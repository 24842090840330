import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        {" "}
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/rechtliche-hinweise"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div></div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="metatext">
                Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/kontakt/">Kontakt</a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/loperamid/">Wie wirkt IMODIUM<sup>®</sup>?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727">
                      <a href="/fakten-uber-durchfall/">
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    {/* <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Info zu Reizdarm</a>
                    </li> */}
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                      <span class="info_icon"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">&nbsp;</div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <h1>
                      <div className="field field-name-field-title-text field-type-text field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            NUTZUNGSBEDINGUNGEN
                          </div>
                        </div>
                      </div>
                    </h1>
                    <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                      <div className="field-items">
                        <div
                          className="field-item even"
                          property="content:encoded"
                        >
                          <h3>Anwendungsbereich</h3>
                          <p>
                            Diese Website gehört der JNTL Consumer Health II (Switzerland) GmbH (im
                            Folgenden Betreiber). Sie richtet sich an Besucher
                            in der Schweiz. Ihre Benutzung unterliegt den
                            folgenden Nutzungsbedingungen. Wenn Sie Services
                            dieser Websites in Anspruch nehmen, gelten daher
                            diese Nutzungsbedingungen.&nbsp;
                          </p>
                          <p>
                            Beachten Sie bitte, dass wir unsere
                            Nutzungsbedingungen unseren Services, Anforderungen
                            und mit Rücksicht auf Gesetzesänderungen von Zeit zu
                            Zeit anpassen. Bitte schauen Sie immer wieder auf
                            diese Seite, um von solchen Änderungen zu erfahren.
                            Ein ausdrücklicher Hinweis auf die Änderung der
                            Nutzungsbedingungen erfolgt nicht. Daher sollten Sie
                            sich bei jeder Nutzung von den jeweils aktuellen
                            Nutzungsbedingungen überzeugen. Der Stand ist
                            datumsmässig&nbsp;am Ende dieser Nutzungsbedingungen
                            benannt.
                          </p>
                          <h3>Aktualität</h3>
                          <p>
                            Der Betreiber dieser Website wird in angemessener
                            Weise Anstrengungen unternehmen, damit diese Website
                            aktuelle und genaue Informationen enthält. Er gibt
                            aber keine Zusicherungen, Gewährleistungen oder
                            Zusagen hinsichtlich der Richtigkeit, Gültigkeit
                            oder Vollständigkeit der bereitgestellten –
                            unverbindlichen – Informationen. Die auf dieser
                            Webseite beworbenen Produkte sind Arzneimittel.
                            Bezüglich Anwendung und Nebenwirkungen ist
                            ausschliesslich die Packungsbeilage massgebend. Der
                            Betreiber haftet daher nicht für Schäden, die sich
                            daraus ergeben, dass Sie sich auf Informationen auf
                            dieser Website verlassen haben.
                          </p>
                          <h3>Unsere Verantwortung</h3>
                          <ul>
                            <li>
                              Diese Website kann auch Links oder Verweise auf
                              andere Websites enthalten, aber die Betreiber
                              dieser Website übernehmen keine Verantwortung für
                              den Inhalt solcher Websites und keine Haftung für
                              Schäden oder Verletzungen, die aus der Nutzung –
                              gleich welcher Art – solcher Inhalte entstehen.
                              Links zu anderen Websites werden den Nutzern
                              dieser Website lediglich als Annehmlichkeit zur
                              Verfügung gestellt.
                            </li>
                            <li>
                              Der Betreiber haftet unbeschränkt für Vorsatz und
                              grobe Fahrlässigkeit dem Grunde nach. Eine Haftung
                              für Folgeschäden, namentlich für entgangenen
                              Gewinn oder immaterielle Schäden, ist – soweit
                              zulässig – ausgeschlossen. Für Ihre Datenverluste
                              oder sonstige Schäden an Ihrer Hard- oder Software
                              ist der Betreiber nicht verantwortlich. Der
                              Betreiber haftet allerdings unbeschränkt für
                              Schäden aus der Verletzung des Lebens, des Körpers
                              oder der Gesundheit. Die vorgenannten
                              Haftungsbeschränkungen gelten zugunsten der
                              Beschäftigten, der gesetzlichen Vertreter und der
                              Erfüllungsgehilfen des Betreibers und seiner
                              Dienstleister entsprechend.
                            </li>
                            <li>
                              Der Betreiber tritt nicht für Schäden ein, die
                              sich aus dem Zugriff oder fehlenden Zugriff auf
                              diese Website ergeben.
                            </li>
                          </ul>
                          <h3>Ihre Verantwortung: Verbindliche Netiquette</h3>
                          <ul>
                            <li>
                              Sofern Sie die Services des Betreibers in Anspruch
                              nehmen, sind wahrheitsgemässe&nbsp;und
                              vollständige Angaben zu Ihrer Person –
                              insbesondere Vorname, Name, Adresse, Telefonnummer
                              sowie E-Mail-Adresse – unabdingbare Voraussetzung.
                              Diese Website differenziert, welche einzelnen
                              Angaben von Ihnen für die jeweiligen Services
                              verbindlich oder unverbindlich sind. Bitte
                              aktualisieren Sie Ihre persönlichen Angaben, wenn
                              sie sich ändern.&nbsp;
                            </li>
                            <li>
                              Der Absender jeglicher Kommunikation an diese
                              Website oder an den Betreiber ist für den Inhalt
                              sowie die darin enthaltenen Informationen,
                              einschliesslich&nbsp;deren Richtigkeit und
                              Genauigkeit, verantwortlich.
                            </li>
                            <li>
                              Bitte geben Sie Ihre Zugangsdaten nicht weiter.
                              Ein Nutzerkonto ist ausschliesslich&nbsp;für Ihren
                              persönlichen Gebrauch vorgesehen. Halten Sie Ihren
                              Benutzernamen und Ihr Passwort vor anderen geheim.
                              Wenn Sie den Eindruck haben, Dritte würden unter
                              Ihrem Benutzernamen handeln, informieren Sie uns
                              bitte unverzüglich; schriftlich oder gern per
                              E-Mail an{" "}
                              <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pFAAQ" target="_blank" >
                              https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pFAAQ
                              </a>{" "}
                              .
                            </li>
                            <li>
                              Es ist untersagt, Material auf die Website zu
                              übertragen, das schadenstiftende Inhalte
                              (insbesondere Malicious Code) und sonstige
                              Programme enthält, welche die Funktionsfähigkeit
                              von Hard- und/oder Software gefährden oder stören
                              können. Sonstige Handlungen, die die System- oder
                              Netzwerksicherheit verletzen oder dies
                              beabsichtigen, wie beispielsweise das Verschaffen
                              eines unautorisierten Zugangs durch das
                              Einschleusen eines Malicious Code oder die
                              Versendung von Spam, sind nicht erlaubt.
                            </li>
                            <li>
                              Der Nutzer hat für die Nachteile einzustehen, die
                              durch seine missbräuchliche oder rechtswidrige
                              Verwendung der Website entstehen.
                            </li>
                          </ul>
                          <h3>Kindern / Jugendliche</h3>
                          <p>
                            Wenn Sie noch nicht volljährig sind, nutzen Sie
                            diese Website nur mit Zustimmung Ihrer
                            Erziehungsberechtigten. Eine Registrierung oder
                            Eingaben und Abrufe kommt also nur in Betracht, wenn
                            Sie mindestens das 18. Jahre Lebensjahr vollendet
                            haben und voll geschäftsfähig sind.
                          </p>
                          <h3>
                            Ihre Nutzungsrechte / Unsere Urheber- / Markenrechte
                            /
                          </h3>
                          <ul>
                            <li>
                              Die Marken, Markennamen und Firmen dieser Website
                              wie ihre Bilder, Texte und/oder grafischen
                              Gestaltungen sind in Deutschland sowie
                              international marken-, namenschutz- und/oder
                              urheberrechtlich geschützt. Sie dürfen in keiner
                              Weise ohne die vorherige schriftliche Zustimmung
                              des Betreiber dieser Website genutzt oder sonst
                              verwendet werden ausser&nbsp;zur Identifizierung
                              der Produkte oder Serviceleistungen des
                              Unternehmens und im Übrigen
                              ausschliesslich&nbsp;für den privaten, nicht
                              gewerblichen Gebrauch.
                            </li>
                            <li>
                              Markenbezeichnungen und Urheberrechtshinweise
                              dürfen in keinem Fall verändert oder beseitigt
                              werden. Die kommerzielle Nutzung, insbesondere das
                              Einstellen kommerzieller Werbung, ist untersagt.
                            </li>
                            <li>
                              Im Übrigen wird durch die Benutzung der Website
                              weder ausdrücklich, konkludent noch auf sonstige
                              Weise eine Lizenz oder ein Recht zum Gebrauch
                              geistiger Eigentumsrechte gewährt, deren
                              Gegenstand auf dieser Website enthalten ist.
                              Jegliche widerrechtliche Verwendung ist
                              ausdrücklich untersagt.
                            </li>
                            <li>
                              Aus den Nutzungsbedingungen folgende Rechte und
                              Pflichten sind auch nicht teilweise ohne die
                              vorherige Zustimmung des Betreibers durch Sie auf
                              Dritte übertragbar.
                            </li>
                            <li>
                              Der Betreiber steht nicht dafür ein, dass das auf
                              der Website angezeigte Material nicht in Rechte
                              Dritter eingreift.
                            </li>
                          </ul>
                          <h3>Datenschutzverweis</h3>
                          <p>
                            Alle personenbezogenen Daten, die in elektronischer
                            Kommunikation über diese Website übermittelt werden,
                            unterliegen dem Datenschutz.{" "}
                            <a href="/datenschutz/">Hier</a> finden Sie
                            ausführliche Informationen über die Verwendung und
                            Speicherung Ihrer Daten durch diese Website.
                          </p>
                          <h3>Beendigung</h3>
                          <p>
                            Der Betreiber behält sich das Recht vor, den Inhalt
                            dieser Website jederzeit aus freiem Grund ohne
                            Ankündigung zu streichen, zu ändern oder zu
                            ergänzen, sofern nicht ausdrücklich Services auf
                            anderer Grundlage erbracht werden. Ein
                            Rechtsanspruch besteht nur dann, wenn dieser
                            ausdrücklich begründet oder kraft Gesetzes gegeben
                            ist.
                          </p>
                          <h3>Anwendbares Recht</h3>
                          <p>
                            Diese Nutzungsbedingungen unterliegen – soweit
                            zulässig – dem schweizerischem Recht.
                          </p>
                          <h3>Unwirksamkeit</h3>
                          <p>
                            Sollten einzelne Bestimmungen dieser
                            Nutzungsbedingungen unwirksam sein oder werden oder
                            den gesetzlichen Regelungen widersprechen, so wird
                            hierdurch die Wirksamkeit der übrigen
                            Nutzungsbedingungen nicht berührt. Die unwirksame
                            Bestimmung wird von den Parteien einvernehmlich
                            durch eine solche Bestimmung ersetzt, welche dem
                            wirtschaftlichen Zweck der unwirksamen Bestimmung in
                            rechtswirksamer Weise am nächsten kommt. Die
                            vorstehende Regelung gilt entsprechend bei
                            Regelungslücken.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                    Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                  </p>
                  <p>
                    © JNTL Consumer Health II (Switzerland) GmbH 2011-2023. Diese Website wird durch die
                    JNTL Consumer Health II (Switzerland) GmbH, Zug veröffentlicht, die die alleinige
                    Verantwortung für den Inhalt trägt.
                    <br />
                    Diese Website richtet sich ausschliesslich an Benutzer aus
                    der Schweiz. Letzte Aktualisierung am: 01. Februar 2023, CP-216083
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>

      </div>
    );
  }
}

export default Page;
